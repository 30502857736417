import classes from "./Footer.module.css";
import {
    FaYoutube,
    FaLinkedinIn,
    FaFacebook,
    FaInstagram,
    FaTiktok,
    FaTelegramPlane,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import styled from "styled-components";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const [showNavList, setShowNavList] = useState(false)
    const toggleNavList = () => setShowNavList(!showNavList)
    const { t } = useTranslation();

    return (
        <footer id='footer'>
            <div className={classes.lower}>
                <section className={classes.about}>
                    <p className="text-justify">
                        <br/>
                        <br/>
                        <p style={{textDecoration: "underline"}}>Swisseva SA :</p>
                        <br/>
                        <a href="https://maps.app.goo.gl/zvDy8FFS1YxTSGTg7" className='link link--nav'
                           style={{fontWeight: "bold"}}>
                            Place Cornavin 14, 1201 Genève
                        </a>
                        <br/>
                        <br/>
                        <a href="mailto:contact@swisseva.com" className='link link--nav'
                           style={{fontWeight: "bold"}}>
                            contact@swisseva.com
                        </a>
                        <br/>
                        <br/>
                        <a href="https://www.swisseva.com" className='link link--nav'
                           style={{fontWeight: "bold"}}>
                            www.swisseva.com
                        </a>
                        <br/>
                        <br/>
                        <p style={{textDecoration: "underline"}}>WhatsApp:</p>
                        <a href="tel:+41767707777" className='link link--nav'
                           style={{fontWeight: "bold"}}>
                            +41767707777
                        </a>
                        <br/>
                        <br/>
                        <p style={{textDecoration: "underline"}}>Standard:</p>
                        <a href="tel:+41227313131" className='link link--nav'
                           style={{fontWeight: "bold"}}>
                            +41227313131
                        </a>
                    </p>
                </section>
                <section className={classes.networks}>
                    <div className={classes.networks_container}>
                        <a href="https://www.youtube.com/@Swisseva_ch" className={classes.networks_a}>
                            <Youtube/>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61560048449021"
                           className={classes.networks_a}>
                            <Facebook/>
                        </a>
                        <a href="https://twitter.com/swisseva_ch" className={classes.networks_a}>
                            <Twitter/>
                        </a>
                        <a href="https://www.instagram.com/swisseva_geneve/" className={classes.networks_a}>
                            <Instagram/>
                        </a>
                        <a href="https://www.linkedin.com/company/swisseva" className={classes.networks_a}>
                            <Linkedin/>
                        </a>
                        <a href="https://www.tiktok.com/@swisseva_ch" className={classes.networks_a}>
                            <Tiktok/>
                        </a>
                        <a href="https://t.me/Swisseva" className={classes.networks_a}>
                            <Telegram/>
                        </a>
                    </div>
                </section>
            </div>
                <div className={classes.branch_list}>
                    <p className={classes.p_footer}>© 2024, Cerruti Immobilier SA</p>
                </div>
        </footer>
    );
};

const Youtube = styled(FaYoutube)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Facebook = styled(FaFacebook)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Twitter = styled(FaXTwitter)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Linkedin = styled(FaLinkedinIn)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Instagram = styled(FaInstagram)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Tiktok = styled(FaTiktok)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

const Telegram = styled(FaTelegramPlane)`
    transition: all 0.3s;
    max-height: 30px;
    max-width: 30px;
    min-width: 22px;
    min-height: 22px;

    &:hover {
        color: #d91a27 !important;
        transform: scale(1.5);
    }
`;

export default Footer;
