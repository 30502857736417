import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import './Navbar.css'

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const [showNavList, setShowNavList] = useState(false)

    const toggleNavList = () => setShowNavList(!showNavList)

    return (
        <nav className='center nav'>
            <ul
                style={{display: showNavList ? 'flex' : null}}
                className='nav__list'
            >
                    <li className='nav__list-item'>
                        <a
                            href='#projects'
                            onClick={toggleNavList}
                            className='link link--nav'
                        >
                            {t("navbar.navProjects")}
                        </a>
                    </li>

                    <li className='nav__list-item'>
                        <a
                            href='#event'
                            onClick={toggleNavList}
                            className='link link--nav'
                        >
                            {t("navbar.navEvent")}
                        </a>
                    </li>
                <li className='nav__list-item'>
                    <a
                        href='#team'
                        onClick={toggleNavList}
                        className='link link--nav'
                    >
                        {t("navbar.navteam")}
                    </a>
                </li>
            </ul>
                <label htmlFor="language" className='btn--lange'>
                    <select
                        onChange={(e) => i18n.changeLanguage(e.target.value)}
                        id="language"
                        name="language"
                    >
                        <option selected value="en">EN</option>
                        <option value="fr">FR</option>
                        <option value="de">DE</option>
                        <option value="it">IT</option>
                        <option value="es">ES</option>
                    </select>
                </label>
            <a href="tel:+41767707777">
                <span type='button' className='btn btn--outline mobile_button_nav'>
                     {t("navbar.navButton")}
                </span>
            </a>
            <button
                type='button'
                onClick={toggleNavList}
                className='btn btn--icon nav__hamburger'
                aria-label='toggle navigation'
            >
                {showNavList ? <CloseIcon/> : <MenuIcon/>}
            </button>
        </nav>
    )
}

export default Navbar
