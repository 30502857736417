import React from 'react'
import './Event.css'
import {useTranslation} from "react-i18next";
const Event = () => {
    const { t } = useTranslation();

    return (
        <section className='section' id='event'>
            <div className='event center'>
                <div className='title'>
                    <p className='event__title'>
                        {t("event.title")}
                    </p>
                </div>
               <h2 className='event__role'>{t("event.name")}</h2>
                <p className='event__desc'>{t("event.description")}</p>
                <div className='event__contact'>
                    <a href="tel:+41767707777">
                        <span type='button' className='btn btn--outline'>
                          {t("event.button")}
                        </span>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Event
