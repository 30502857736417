import Navbar from '../../../components/Navbar/Navbar'
import './Header.css'
import React from "react";
const Header = () => {
    return (
        <header className='header center'>
            <img className='header_logo' src={"/img/Header/Cerruti Logo.png"}
                 alt="logo"/>
            <Navbar/>
        </header>
    )
}

export default Header
